export const formatCategories = (categories) => {
  return categories.map((category) => {
    return {
      label: formatTitle(category.nombre || category.title),
      value: category.nombre,
      code: category.codigo,
      type: 'categoria',
      childs: [],
      ...category,
    }
  })
}

export const formatCharacteristics = ({
  caracteristicas,
  caracteristicasValores,
}) => {
  return caracteristicas?.map((characteristic) => {
    return {
      label: formatTitle(characteristic.nombre),
      value: characteristic.codigo,
      type: 'caracteristicas',
      filtrable: characteristic.filtrable,
      characteristic: characteristic.caracteristica,
      values: caracteristicasValores.filter(
        (characteristicValue) =>
          Number(characteristicValue.caracteristica) === characteristic.codigo
      ),
    }
  })
}

export const findChildsOfCategory = (currentCategory, categories = []) => {
  const childs = []
  if (!currentCategory?.child) return []

  for (const categoryID of currentCategory.child) {
    // eslint-disable-next-line eqeqeq
    // const childCategory = categories.filter((category) => category.id == categoryID)[0];
    const childCategory = categories.find(
      (category) => category.id === categoryID && category.filtrable
    )
    if (childCategory) {
      const subChild = []
      if (childCategory?.child?.length) {
        for (const sc of childCategory?.child) {
          // eslint-disable-next-line eqeqeq
          const subChildCategory = categories.find(
            (category) => category.id === sc && category.filtrable
          )
          // const subChildCategory = categories.filter((category) => category.id == sc)[0];
          if (subChildCategory) {
            subChild.push({
              id: subChildCategory.id,
              label: subChildCategory.nombre,
              value: subChildCategory.nombre,
              type: 'categoria',
              title: subChildCategory.nombre,
              code: subChildCategory.codigo,
              nivel: subChildCategory.nivel,
            })
          }
        }
      }
      childs.push({
        id: childCategory.id,
        label: childCategory.nombre,
        value: childCategory.nombre,
        type: 'categoria',
        title: childCategory.nombre,
        code: childCategory.codigo,
        nivel: childCategory.nivel,
        childs: subChild,
      })
    }
  }
  return childs
}

// TODO REFACTOR
export const formatCategoriesInTopMenu = (categories) => {
  return categories.map((c) => {
    const name = c.nombre
    const childs = findChildsOfCategory(c, categories)

    return {
      name: name,
      code: c.codigo,
      title: name,
      label: name,
      value: name,
      type: 'categoria',
      ...c,
      childs: [...childs],
    }
  })
}

export const formatTitle = (title) => {
  return title[0].toUpperCase() + title.toLowerCase().substring(1)
}

export const filterList = [
  {
    label: 'Ordenar Por',
    childs: [
      { label: 'Mayor Precio', value: 'ASC' },
      { label: 'Menor Precio', value: 'DESC' },
    ],
  },
  {
    label: 'Identificación animal',
    value: '12',
    type: 'linea',
    childs: [
      { label: 'Caravanas Oficiales', value: '91', type: 'subLinea' },
      { label: 'Caravanas De Manejo', value: '37', type: 'subLinea' },
      { label: 'Identificación electrónica', value: '91', type: 'subLinea' },
      {
        label: 'Accesorios de identificación animal',
        value: '96',
        type: 'subLinea',
      },
    ],
  },
  {
    label: 'Insumos Veterinarios',
    value: '10',
    type: 'linea',
    childs: [
      { label: 'Instrumental de cirugía', value: '147', type: 'subLinea' },
      { label: 'Accesorios de laboratorio', value: '5', type: 'subLinea' },
    ],
  },
  {
    label: 'Salud animal',
    value: '38',
    type: 'linea',
    childs: [
      { label: 'Reproducción', value: '165', type: 'subLinea' },
      { label: 'Vacunación', value: '6', type: 'subLinea' },
      { label: 'Jeringas descartables', value: '69', type: 'subLinea' },
      { label: 'Sistema de pesaje', value: '850', type: 'subLinea' },
    ],
  },
  {
    label: 'Bovinos',
    value: '14',
    type: 'linea',
    childs: [
      { label: 'Accesorios bovinos', value: '3', type: 'subLinea' },
      { label: 'Tambo', value: '127', type: 'subLinea' },
      { label: 'Comederos', value: '33', type: 'subLinea' },
      { label: 'Herramientas bovinas', value: '41', type: 'subLinea' },
      { label: 'Peladoras y peines bovinos', value: '892', type: 'subLinea' },
    ],
  },
  {
    label: 'Ovinos',
    value: '15',
    type: 'linea',
    childs: [
      { label: 'Accesorios ovinos', value: '129', type: 'subLinea' },
      { label: 'Anillos', value: '11', type: 'subLinea' },
      { label: 'Peladoras y peines ovinos', value: '891', type: 'subLinea' },
      { label: 'Tijeras', value: '106', type: 'subLinea' },
    ],
  },
  {
    label: 'Porcinos',
    value: '16',
    type: 'linea',
    childs: [
      { label: 'Bebederos', value: '135', type: 'subLinea' },
      { label: 'Grampas y argollas', value: '58', type: 'subLinea' },
      { label: 'Pinzas', value: '86', type: 'subLinea' },
    ],
  },
  {
    label: 'Equinos',
    value: '17',
    type: 'linea',
    childs: [
      { label: 'Herraduras', value: '62', type: 'subLinea' },
      { label: 'Clavos', value: '32', type: 'subLinea' },
      // { label: 'Vendas', value: '91', type: 'subLinea' },
      { label: 'Herramientas equinos', value: '123', type: 'subLinea' },
      { label: 'Accesorios equinos', value: '128', type: 'subLinea' },
    ],
  },
  {
    label: 'Mascotas',
    value: '18',
    type: 'linea',
    childs: [
      { label: 'Peladoras y peines pequeños', value: '89', type: 'subLinea' },
      { label: 'Accesorios pequeños', value: '85', type: 'subLinea' },
    ],
  },
  {
    label: 'Rural',
    value: '71',
    type: 'linea',
    childs: [
      { label: 'Electrificación', value: '47', type: 'subLinea' },
      { label: 'Accesorios rurales', value: '2', type: 'subLinea' },
      { label: 'Solar', value: '99', type: 'subLinea' },
      { label: 'Bolsa para semilla', value: '102', type: 'subLinea' },
      { label: 'Saneamiento ambiental', value: '174', type: 'subLinea' },
      { label: 'Media sombra', value: '104', type: 'subLinea' },
      { label: 'Pilas', value: '180', type: 'subLinea' },
      { label: 'Pulverizadores', value: '46', type: 'subLinea' },
      { label: 'Termometros', value: '105', type: 'subLinea' },
      { label: 'Indumentaria rural', value: '113', type: 'subLinea' },
      { label: 'Hilo', value: '63', type: 'subLinea' },
    ],
  },
  {
    label: 'Conservación grano/forraje',
    value: '25',
    type: 'linea',
    childs: [
      { label: 'Accesorios de bolsas para silo', value: '1', type: 'subLinea' },
      { label: 'Bolsas para silo', value: '102', type: 'subLinea' },
      { label: 'Inoculantes', value: '65', type: 'subLinea' },
      // { label: 'Instrumental de medicion', value: '91', type: 'subLinea' },
      { label: 'Lonas de uso agricola', value: '75', type: 'subLinea' },
    ],
  },
  {
    label: 'Atributos',
    childs: [],
  },
  {
    label: 'Características',
    childs: [],
  },
  {
    label: 'Precio',
  },
]
