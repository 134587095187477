import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
} from '@mui/material'
import DiscountIcon from '../../assets/discount.svg'
import BottomActions from '../../components/BottomActions'
import { useStyles } from './CheckoutSummary.styles'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from '../../utils/formatNumber'
import {
  setCuponDiscount,
  setNewCuponDiscount,
} from 'stores/cartStore/actionsCart'
import { useEffect, useMemo, useState } from 'react'
import { getCartSummary } from './helpers/helpers'
import { getAvailableBalance } from 'pages/ResumePage/services/getAvailableBalance'
import { updateBalance } from 'stores/userStore/actionsUser'
import currencyToNumber from 'utils/currencyToNumber'
import {
  getProductParticipatingInPromotion,
  promotionsCartChanges,
} from 'pages/ProductPage/utils/getProductDiscounts'
import PopupPromotionProducts from 'components/PopupPromotionProducts/PopupPromotionProducts'
import { useLocation } from 'react-router-dom'
import { requestQueue } from 'utils/requestsQueue'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

function CheckoutSummary({
  firstAction,
  firstActionDisabled,
  secondAction,
  firstText = 'Continuar',
  secondText = 'Volver',
  thirdText,
  thirdAction,
  shouldApplyTax,
  aliquot,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const location = useLocation()
  const cart = useSelector((state) => state.cart)
  const { currentBranch, userInfo } = useSelector(({ userAuth }) => userAuth)
  const { deuda, saldoDisponible: balance, isEnabled } = userInfo
  const [summaryValues, setSummaryValues] = useState({})
  const [productParticipeInfo, setProductParticipateInfo] = useState([])
  const [promoPopupIsOpen, setPromoPopup] = useState(false)
  const { product, product_manejo, product_official } = cart
  product.sort((a, b) => Number(a.sku) - Number(b.sku))
  product_manejo.sort((a, b) => Number(a.sku) - Number(b.sku))
  product_official.sort((a, b) => Number(a.sku) - Number(b.sku))
  const allProducts = [...product, ...product_manejo, ...product_official]

  useEffect(() => {
    getCartSummary(cart, shouldApplyTax, aliquot, userInfo)
      .then(setSummaryValues)
      .catch(console.error)

    const participateInfo = getProductParticipatingInPromotion(
      cart,
      userInfo?.promociones
    )
    setProductParticipateInfo(
      participateInfo.filter(({ quantity }) => quantity === 0)
    )
  }, [cart, shouldApplyTax, aliquot, userInfo?.promociones])

  const cartCupon = useMemo(() => cart?.cuponDiscount, [cart?.cuponDiscount])

  useEffect(() => {
    getAvailableBalance(userInfo?.codigoUsuario).then((response) => {
      dispatch(
        updateBalance({
          saldoDisponible: currencyToNumber(response.disponible),
          deuda: currencyToNumber(response.deuda),
          isEnabled: response.isEnabled,
        })
      )
    })
  }, [])

  const cupons = useMemo(() => {
    const promotions = promotionsCartChanges(allProducts, userInfo?.promociones)
    const availableCupons = promotions
      .filter(({cupon}) => cupon)
      .map(({cupon, promotionCode}) => ({cupon, promotionCode}))
      .reduce((acc, curr) => acc.find(({cupon}) => cupon === curr.cupon) ? acc : [...acc, curr] ,[])

    if (
      availableCupons.length === 0 &&
      cart.cuponSelected &&
      !requestQueue.executing
    ) {
      dispatch(setNewCuponDiscount(null))
    }
    return availableCupons
  }, [allProducts, userInfo?.promociones])

  const formatBalance = (balance) => {
    if (!balance) return 0
    if (typeof balance === 'number') return balance
    balance = balance.replace(/\./g, '')
    return parseInt(parseFloat(balance))
  }

  const handleAddCupon = (cupon) => {
    const cuponCode = cupons.find((c) => c.cupon === cupon)?.promotionCode
    if (cuponCode?.includes('PR16')) {
      dispatch(setCuponDiscount(cupons.find((c) => c.cupon === cupon)))
    } else {
      dispatch(setCuponDiscount(''))
    }
    dispatch(setNewCuponDiscount(cupon))
  }

  const cartCuponAmountDiscount = useMemo(() => {
    return summaryValues?.bonificaciones
      ?.filter(({ cupon }) => cupon === cart.cuponSelected)
      .reduce((acc, curr) => {
        acc += curr.amount
        return acc
      }, 0)
  }, [cartCupon?.descuentoPesos, summaryValues.bonificaciones])

  return (
    <>
      <Box className={classes.boxPrecio}>
        <Grid item container>
          <FormControl fullWidth>
            <TextField
              id='outlined-basic'
              select
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton aria-label='delete' size='large'>
                      <img src={DiscountIcon} alt='descuento' />
                    </IconButton>{' '}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <span
                      onClick={() => handleAddCupon(cart.cuponSelected)}
                      className={classes.couponButton}
                    >
                      Aplicar
                    </span>
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                IconComponent: () => null,
                value: cart.cuponSelected,
                defaultValue: '',
                placeholder: 'Cupón de descuento',
                displayEmpty: true,
              }}
              onChange={(e) => handleAddCupon(e.target.value)}
            >
              {cupons?.map(({ cupon }) => (
                <MenuItem key={cupon} value={cupon}>
                  {cupon}
                </MenuItem>
              ))}
            </TextField>
            {!cart.cuponSelected && cupons.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  className={classes.thereAreCouponsAvailableText}
                  fontSize={'medium'}
                />
                <p
                  className={classes.thereAreCouponsAvailableText}
                  style={{ fontSize: 12 }}
                >
                  Tienes {cupons.length}{' '}
                  {cupons.length > 1
                    ? 'cupones disponibles'
                    : 'cupón disponible'}
                  !
                </p>
                <KeyboardDoubleArrowUpIcon
                  className={classes.thereAreCouponsAvailableText}
                  fontSize={'medium'}
                />
              </div>
            )}
          </FormControl>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.borderMargin}
            container
            justifyContent='space-between'
          >
            <Typography paragraph className={classes.textMid}>
              Subtotal
            </Typography>
            <Typography paragraph className={classes.textNumber}>
              {formatNumber(summaryValues?.subtotal?.toString())}
            </Typography>
          </Grid>

          {shouldApplyTax && (
            <Grid
              item
              xs={12}
              className={classes.borderMargin}
              container
              justifyContent='space-between'
            >
              <Typography paragraph className={classes.textMid}>
                IVA
              </Typography>
              <Typography paragraph className={classes.textNumber}>
                {formatNumber((summaryValues?.taxAmount || 0).toString())}
              </Typography>
            </Grid>
          )}
          {!!summaryValues?.totalDiscounts && (
            <Grid
              item
              xs={12}
              className={classes.borderMargin}
              container
              justifyContent='space-between'
            >
              <Typography paragraph className={classes.textMid}>
                Descuentos aplicados:
              </Typography>
              <Typography paragraph className={classes.textNumber}>
                - {formatNumber(summaryValues?.totalDiscounts?.toString())}
              </Typography>
            </Grid>
          )}
          {summaryValues.bonificaciones
            ?.reduce(
              (acc, curr) =>
                curr.cupon && acc.some(({ cupon }) => cupon === curr.cupon)
                  ? acc
                  : [...acc, curr],
              []
            )
            .map(({ name, amount, cupon, pr }) => (
              <Box
                key={name}
                className={classes.borderMargin}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                container
                justifyContent='space-between'
              >
                <Typography paragraph className={classes.textMid}>
                  {cupon ? 'Cupón ' + cupon : `Bonificación "${name}"`}
                </Typography>
                {amount > 0 && !pr.promotionCode.includes('PR16') && (
                  <Typography
                    paragraph
                    className={classes.textNumber}
                    sx={{ textAlign: 'end' }}
                  >
                    - {formatNumber((amount || 0).toString())}
                  </Typography>
                )}
                {cupon && (
                  <Typography
                    paragraph
                    className={classes.textNumber}
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setNewCuponDiscount(null))
                      dispatch(setCuponDiscount(null))
                    }}
                  >
                    Cancelar cupón
                  </Typography>
                )}
              </Box>
            ))}

          <Grid
            item
            xs={12}
            className={classes.borderMargin}
            container
            justifyContent='space-between'
          >
            <Typography paragraph className={classes.textMid}>
              Bonificacion comercial (0%):
            </Typography>
            <Typography paragraph className={classes.textNumber}>
              $0
            </Typography>
          </Grid>
          {cartCupon && (
            <Grid
              item
              xs={12}
              className={classes.borderMargin}
              container
              justifyContent='space-between'
            >
              <Typography paragraph className={classes.textMid}>
                Cupones{' '}
                {cartCupon?.descuentoPorcentaje
                  ? `(${cartCupon?.descuentoPorcentaje})%`
                  : ''}
                :
              </Typography>
              <Typography paragraph className={classes.textNumber}>
                {cartCupon ? '- ' : null}
                {formatNumber((cartCuponAmountDiscount || 0).toString())}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.borderMargin}
            container
            justifyContent='space-between'
          >
            <Typography paragraph className={classes.textMid}>
              Retiro en sucursal de
              <Typography
                component='span'
                className={classes.textSucursal}
                sx={{ marginLeft: '5px' }}
              >
                {currentBranch?.name}
              </Typography>
            </Typography>
            <Typography paragraph className={classes.textNumber}>
              $ 0
            </Typography>
          </Grid>

          {shouldApplyTax && (
            <Grid
              item
              xs={12}
              className={classes.borderMargin}
              container
              justifyContent='space-between'
            >
              <Typography paragraph className={classes.textMid}>
                Alicuouta
              </Typography>
              <Typography paragraph className={classes.textNumber}>
                {formatNumber((summaryValues?.aliquot || 0).toString())}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            container
            justifyContent='space-between'
            className={classes.borderMarginEnd}
          >
            <Typography paragraph className={classes.textTotal}>
              Total
            </Typography>
            <Typography paragraph className={classes.textColor}>
              {formatNumber(Math.abs(summaryValues?.total).toString())} + IVA
            </Typography>
          </Grid>
          {/* <Grid item xs={12} container justifyContent='flex-end'>
            <Typography
              sx={{
                color: 'gray',
                fontSize: '12px',
                fontWeight: 500,
                textAlign: 'right',
                marginTop: '-10px',
                marginBottom: '10px',
              }}
            >
              * El precio indicado no incluye IVA
            </Typography>
          </Grid> */}
          {!shouldApplyTax &&
            (summaryValues?.total > formatBalance(balance) ||
              formatBalance(deuda) > 0 ||
              isEnabled === false) && (
              <Typography
                sx={{
                  color: 'red',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                El pedido requerirá una aprobación financiera
              </Typography>
            )}

          <BottomActions
            cart={cart}
            firstAction={
              productParticipeInfo.length > 0 &&
              location.pathname?.includes('/carrito')
                ? () => setPromoPopup(true)
                : firstAction
            }
            firstActionDisabled={firstActionDisabled}
            secondAction={secondAction}
            firstText={firstText}
            secondText={secondText}
            thirdText={thirdText}
            thirdAction={thirdAction}
            customStylesContainer={(theme) => ({
              [theme.breakpoints.up('sm')]: {
                marginTop: '26px !important',
              },
            })}
          />
        </Grid>
      </Box>
      <PopupPromotionProducts
        isOpen={promoPopupIsOpen}
        close={() => setPromoPopup(false)}
        productParticipeInfo={productParticipeInfo}
        continueAction={firstAction}
        firstActionDisabled={firstActionDisabled}
      />
    </>
  )
}

export default CheckoutSummary
